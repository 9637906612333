<template>
    <grid-view 
        title="Критерии оценивания"
        filter
        create
        create-title="Создать элемент"
        url-create="/scoring-criteria/create"
        url-update="/scoring-criteria/update/:id"
        store-module="hint"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Название', value: 'name', width: 1, sortable: true },
            { text: 'Обновлен', value: 'updated_at', width: 1, sortable: true },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'create_similar', width: 1, sortable: false }
            // { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
    >
        <template v-slot:filter="{ filter }">
            
            <v-row>
                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.name"
                        label="Название" 
                        placeholder="1"
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
         <!-- Extra buttons in title -->
         <template #[`title.right`]>
            <v-btn v-if="isUserCan" to="/scoring-criteria/task-relations" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-link
                </v-icon>
                <span>Связи с заданиями</span>
            </v-btn>
        </template>
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'ScoringCriteriaIndex',
    components: { GridView }, 
    data () {
        return {}
    },
    async created () {
        await this.$store.dispatch(`hint/filter`, { ...this.$store.state.hint.filter, type: 'subtask-scoring-criteria' });
    },
    computed: {
        isUserCan () {
            return ['admin'].includes(this.$store.state.user?.profile?.name);
        }
    },
    methods: {
        formatItems (items) {
            return _.map(items, (item) => {
                
                const dateUpdatedAt = new Date(item.updated_at * 1000);

                item.updated_at = _.join([
                    dateUpdatedAt.getDate(),
                    (dateUpdatedAt.getMonth() < 9 ? '0' : '') + (dateUpdatedAt.getMonth() + 1).toString(),
                    dateUpdatedAt.getFullYear()
                ], '.');

                return item;
            })
        } 
    }
}
</script>